var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('app-bar',{attrs:{"height":("" + _vm.appBarHeight),"toggle-mobile-menu":_vm.toggleMobileMenu}}),(_vm.mobileScreen)?_c('v-navigation-drawer',{attrs:{"width":"80%","app":"","right":"","clipped":""},model:{value:(_vm.isMobileMenuOpen),callback:function ($$v) {_vm.isMobileMenuOpen=$$v},expression:"isMobileMenuOpen"}},[_c('v-list',{attrs:{"nav":"","dense":""}},[_c('div',{staticStyle:{"display":"flex","justify-content":"space-between","align-content":"center"}},[_c('v-app-bar-nav-icon',{staticStyle:{"color":"black"},on:{"click":_vm.toggleMobileMenu}}),_c('div',{staticClass:"primary--text text-h6 mr-4",staticStyle:{"text-align":"right"}},[_vm._v(" "+_vm._s(_vm.$t("global.menu"))+" ")])],1),_c('v-divider'),_c('div',{staticClass:"text-right mt-4"},[_vm._l((_vm.template[0].items),function(item,itemIdx){return _c('v-col',{key:("section-0-item-" + itemIdx),staticClass:"py-1",class:{ 'mt-2': itemIdx === 0 },attrs:{"cols":"12"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('div',{staticClass:"cursor-pointer",class:{ 'font-weight-semi-bold': item.bold, 'primary--text': hover },on:{"click":function($event){return _vm.viewRoute(item)}}},[_vm._v(" "+_vm._s(_vm.$t(("footer." + (item.label))))+" ")])]}}],null,true),model:{value:(item.hover),callback:function ($$v) {_vm.$set(item, "hover", $$v)},expression:"item.hover"}})],1)}),_vm._l((_vm.template.slice(1)),function(section,idx){return _c('div',{key:(idx + "-menu"),staticClass:"my-10"},[(section.link)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('div',{staticClass:"cursor-pointer font-weight-semi-bold",class:{ 'primary--text': hover },on:{"click":function($event){_vm.$router.push(_vm.generateRoute(section.link))}}},[_vm._v(" "+_vm._s(_vm.$t(("footer." + (section.title))))+" ")])]}}],null,true),model:{value:(section.hover),callback:function ($$v) {_vm.$set(section, "hover", $$v)},expression:"section.hover"}})],1):_c('v-col',{staticClass:"font-weight-semi-bold",attrs:{"cols":"12"}},[_vm._v(" "+_vm._s(_vm.$t(("footer." + (section.title))))+" ")]),(section.items)?_c('div',_vm._l((section.items),function(item,itemIdx){return _c('v-col',{key:("section-" + idx + "-item-" + itemIdx),staticClass:"py-1",attrs:{"cols":"12"}},[_c('div',{class:{ 'font-weight-semi-bold': item.bold }},[_vm._v(" "+_vm._s(item.type === "ROUTER" ? _vm.$t(("footer." + (item.title))) : item.title)+" ")])])}),1):(section.model)?_c('div',_vm._l((_vm.footer[section.model]),function(item,itemIdx){return _c('v-col',{key:("section-" + idx + "-item-" + itemIdx),staticClass:"py-1",attrs:{"cols":"12"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('div',{staticClass:"cursor-pointer",class:{ 'font-weight-semi-bold': item.bold, 'primary--text': hover },on:{"click":function () { return _vm.viewFooter(section.model, item); }}},[_vm._v(" "+_vm._s(item.type === "ROUTER" || item.static ? _vm.$t(("footer." + (item.title))) : item.title)+" ")])]}}],null,true),model:{value:(item.hover),callback:function ($$v) {_vm.$set(item, "hover", $$v)},expression:"item.hover"}})],1)}),1):_vm._e()],1)})],2)],1)],1):_vm._e(),_c('v-main',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isMobileMenuOpen),expression:"!isMobileMenuOpen"}],staticStyle:{"z-index":"100","display":"flex","flex-direction":"column","position":"fixed","right":"0"},style:({ bottom: _vm.showCookiesBar ? '210px' : '120px' })},[_c('router-link',{attrs:{"to":_vm.generateRoute('CalculateList')}},[_c('v-btn',{staticClass:"rounded-0",class:{ 'rounded-tl-xl': _vm.xl, 'rounded-tl-lg': _vm.lg || _vm.mobileScreen },staticStyle:{"border-bottom":"1px solid white !important"},attrs:{"fab":"","depressed":"","dark":"","width":_vm.fabSize,"height":_vm.fabSize,"color":"black"}},[_c('v-img',{attrs:{"width":_vm.fabIconSize,"height":_vm.fabIconSize,"aspect-ratio":"1","contain":"","src":require('@/assets/app/calculate.png')}})],1)],1),_c('router-link',{attrs:{"to":_vm.generateRoute('Dealers')}},[_c('v-btn',{staticClass:"rounded-0",class:{ 'rounded-bl-xl': _vm.xl, 'rounded-bl-lg': _vm.lg || _vm.mobileScreen },attrs:{"fab":"","depressed":"","dark":"","width":_vm.fabSize,"height":_vm.fabSize,"color":"black"}},[_c('v-img',{attrs:{"width":_vm.fabIconSize,"height":_vm.fabIconSize,"aspect-ratio":"1","contain":"","src":require('@/assets/app/dealer.png')}})],1)],1)],1),(!_vm.isHome)?_c('v-container',{staticClass:"ma-0 pa-0",class:_vm.extraClasses,attrs:{"fluid":""}},[_c('div',{staticStyle:{"position":"relative","width":"100%","overflow":"hidden"},style:({ height: (_vm.heroHeight + "px") })},[_c('img',{staticClass:"pa-0 align-end",style:({
            aspectRatio: _vm.heroRatio,
            objectPosition: _vm.mobileScreen ? 'top center' : 'center center'
          }),attrs:{"alt":"banner","height":_vm.heroHeight,"src":_vm.bannerUrl}}),_c('v-row',{staticClass:"ma-0 pa-0",staticStyle:{"position":"absolute","bottom":"0","left":"0"},attrs:{"align":"end","justify":"start"}},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c(_vm.routeTitle,{tag:"component"})],1)],1)],1),_c('router-view',{staticStyle:{"position":"relative"}})],1):[_c('router-view',{key:((this.$route.name) + "-" + (this.$route.params))})],(_vm.showCookiesBar)?_c('CookiesBar',{on:{"close":_vm.closeCookieBar}}):_vm._e(),_c('v-snackbar',{attrs:{"value":_vm.$store.state.snackBar,"color":"transparent","elevation":"0","right":"","top":""},on:{"input":function($event){return _vm.toggleSnack($event)}}},[_c('v-alert',{staticClass:"black--text",staticStyle:{"background-color":"#fff"},attrs:{"border":"bottom","colored-border":"","type":"warning","elevation":"2"},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-icon',{staticClass:"mr-2",attrs:{"color":"warning"}},[_vm._v("mdi-alert")])]},proxy:true},{key:"append",fn:function(){return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.toggleSnack(false)}}},[_c('v-icon',{staticClass:"black--text"},[_vm._v("mdi-close")])],1)]},proxy:true}])},[_vm._v(" "+_vm._s(_vm.msgSnack)+" ")])],1)],2),_c('Footer')],1)}
var staticRenderFns = []

export { render, staticRenderFns }