import { JsonProperty, Serializable } from "typescript-json-serializer"
// @ts-ignore
import * as urljoin from "url-join"

// const baseFileUrl = 'http://103.58.148.212:5000';

const baseFileUrl = process.env.VUE_APP_FILE_PATH

function makeFileUrl(filePath: string): string {
  return urljoin(baseFileUrl, filePath)
}

@Serializable()
export default class DiamondFile {
  @JsonProperty("file_name") fileName?: string

  @JsonProperty("file_path") filePath?: string

  @JsonProperty("file_type") fileType?: string

  @JsonProperty("file_extension") fileExtension?: string

  @JsonProperty("file_size") fileSize?: number

  @JsonProperty("created_by") createdBy?: string

  @JsonProperty("updated_by") updatedBy?: string

  @JsonProperty({ name: "file_path", beforeDeserialize: makeFileUrl }) fileUrl?: string
}
