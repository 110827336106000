import UCookie from "universal-cookie"

const uCookie = new UCookie()
interface CookieKey {
  key: string
  value: string
  expires?: string | Date | Number
}

export class Cookies {
  static analyticCookies: string[] = ["_ga", "_gid", "_gat_gtag_UA_74056591_1", "_fbp", "fr"]
  static functionalCookies: string[] = ["_dbp_sys"]
  static adsCookies: string[] = ["_dbp_ads"]
  static acceptCookies: string[] = ["_dbp_acc"]
  static acceptAnalytic: string = "_dbp_acc_ana"
  static acceptAds: string = "_dbp_acc_ads"
  static acceptFuctional: string = "_dbp_acc_func"

  static get all(): CookieKey[] {
    const cookies: any = uCookie.getAll()
    let _cookies: CookieKey[] = []
    for (let key in cookies) {
      _cookies.push({ key, value: cookies[key] })
    }
    return _cookies
  }

  static getCookieByKey(name: string): string {
    return uCookie.get(name)
  }

  static setCookies({ key, value, expires }: CookieKey): void {
    let today = new Date()
    const expireDate =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 3).toString().padStart(2, "0") +
      "-" +
      today
        .getDate()
        .toString()
        .padStart(2, "0")
    uCookie.set(key, value, { path: "/", expires: new Date(expireDate) })
  }

  static async setGoogleCookies() {
    //@ts-ignore
    window.gtag("js", new Date())
    //@ts-ignore
    window.gtag("config", "UA-74056591-1")
  }

  static async setFacebookCookies() {
    // @ts-ignore
    !(function(f, b, e, v, n, t, s) {
      // @ts-ignore
      if (f.fbq) return
      // @ts-ignore
      n = f.fbq = function() {
        // @ts-ignore
        n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments)
      }
      // @ts-ignore
      if (!f._fbq) f._fbq = n
      // @ts-ignore
      n.push = n
      // @ts-ignore
      n.loaded = !0
      // @ts-ignore
      n.version = "2.0"
      // @ts-ignore
      n.queue = []
      // @ts-ignore
      t = b.createElement(e)
      // @ts-ignore
      t.async = !0
      // @ts-ignore
      t.src = v
      // @ts-ignore
      s = b.getElementsByTagName(e)[0]
      // @ts-ignore
      s.parentNode.insertBefore(t, s)
      // @ts-ignore
    })(window, document, "script", "https://connect.facebook.net/en_US/fbevents.js")
    // @ts-ignore
    window.fbq("init", "3006613822914096")
    // @ts-ignore
    window.fbq("track", "PageView")

    // @ts-ignore
    window.fbAsyncInit = function() {
      // @ts-ignore
      window.FB.init({
        xfbml: true,
        version: "v3.3"
      })
    }
    ;(function(d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0]
      if (d.getElementById(id)) return
      js = d.createElement(s)
      js.id = id
      // @ts-ignore
      js.src = "https://connect.facebook.net/en_US/sdk/xfbml.customerchat.js"
      // @ts-ignore
      fjs.parentNode.insertBefore(js, fjs)
    })(document, "script", "facebook-jssdk")
  }

  static async setFuctionalCookies() {
    this.functionalCookies.forEach((cookie: string) => {
      this.setCookies({ key: cookie, value: "1" })
    })
    this.setCookies({ key: this.acceptFuctional, value: "1" })
  }

  static async removeFuctionalCookies() {
    this.deleteCookie(this.acceptFuctional)
    this.functionalCookies.forEach((cookie: string) => {
      this.deleteCookie(cookie)
    })
  }

  /**
   * Analytic CookieKey -
   * Google
   * Facebook
   */

  static setAnalyticCookies(): void {
    this.setGoogleCookies()
    this.setFacebookCookies()
    this.setCookies({ key: this.acceptAnalytic, value: "1" })
  }

  static removeAnalyticCookies(): void {
    this.deleteCookie(this.acceptAnalytic)
    this.analyticCookies.forEach((c: string) => {
      uCookie.remove(c, { path: "/", domain: "localhost" })
      uCookie.remove(c, { path: "/", domain: ".facebook.com" })
      uCookie.remove(c, { path: "/", domain: ".google.com" })
      uCookie.remove(c, { path: "/", domain: ".dbp.co.th" })
      uCookie.remove(c, { path: "/", domain: ".knacx.dev" })
    })
    // uCookie.set("temp", "1", { expires: new Date(expireDate) })
  }

  static setAdsCookies(): void {
    this.adsCookies.forEach((cookie: string) => {
      this.setCookies({ key: cookie, value: "1" })
    })
    this.setCookies({ key: this.acceptAds, value: "1" })
  }

  static removeAdsCookies() {
    this.deleteCookie(this.acceptAds)
    this.adsCookies.forEach((cookie: string) => {
      this.deleteCookie(cookie)
    })
  }

  static deleteCookie(key: string): void {
    uCookie.remove(key, { path: "/" })
  }
}
