const menu = {
  supportAndDownload: {
    title: "Support and Download",
    support: "Product Information",
    download: "Download"
  },
  contactUs: {
    title: "Contact Us",
    dealer: "Diamond Dealers",
    career: "Work with us",
    contact: "Contact Us"
  },
  search: "Search"
}

export default menu
