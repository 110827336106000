const contactUs = {
  success: "ส่งข้อความสำเร็จ",
  contact: {
    title: "ส่งข้อความถึงเรา",
    caption1: "สามารถกรอกแบบฟอร์มด้านล่างและกดส่งข้อความเพื่อส่งข้อความถึงเรา",
    caption2: "หากมีภาพหรือเอกสารใดประกอบสามารถเลือกแนบไฟล์มาพร้อมกันได้",
    form: {
      name: "ชื่อ-นามสกุล",
      tel: "เบอร์โทรศัพท์",
      email: "อีเมล",
      subject: "เรื่องที่ต้องการติดต่อ",
      detail: "รายละเอียดข้อความ",
      file: "เลือกไฟล์แนบ",
      fileBtn: "เลือกไฟล์",
      remarks: "เฉพาะไฟล์ (doc, docx, xls, xlsx, ppt, pptx, jpg, jpeg, png, gif, bmp, pdf) " + "ขนาดไม่เกิน 25 MB",
      required: "*จำเป็นต้องกรอก"
    }
  },
  branches: {
    title: "สาขา",
    tel: "โทร: ",
    fax: "โทรสาร: ",
    downloadMap: "ดาวน์โหลดแผนที่"
  }
}

export default contactUs
