import { JsonProperty, Serializable } from "typescript-json-serializer"
import { fullWidth } from "@/types/helper"

@Serializable()
export default class SearchResult {
  @JsonProperty("product_group_id") productGroupId?: number

  @JsonProperty("product_category_id") productCategoryId?: number

  @JsonProperty("news_id") newsId?: number

  @JsonProperty() title!: string

  @JsonProperty("bio_short") bioShort?: string

  @JsonProperty() type!: string

  @JsonProperty() csr?: string

  @JsonProperty() branch?: string

  @JsonProperty("blog_id") blogId?: number

  @JsonProperty("career_id") careerId?: number

  @JsonProperty() position?: string

  @JsonProperty() answer?: string

  @JsonProperty() question?: string

  @JsonProperty("product_category") productCategory?: string

  @JsonProperty() color?: string

  @JsonProperty() caption?: string

  @JsonProperty("shop_name") shopName?: string

  @JsonProperty() tel?: string

  @JsonProperty("download_header_id") downloadHeaderId!: number

  @JsonProperty("support_header_id") supportHeaderId!: number

  @JsonProperty("shade_color_id") shadeColorId?: number

  @JsonProperty({
    name: "i_frame",
    beforeDeserialize: fullWidth
  })
  mapContent?: string

  @JsonProperty() address?: string
}
