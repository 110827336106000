




import { Component, Vue } from "vue-property-decorator"
import TitleShape from "@/components/TitleShape.vue"

@Component({
  name: "SearchTitle",
  components: { TitleShape }
})
export default class SearchTitle extends Vue {}
