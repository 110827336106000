const title = {
  aboutUs: "About Us",
  calculate: "Estimation",
  career: "Work with Us",
  contactUs: "Contact Us",
  cookiePolicy: "Cookies Policy",
  dealer: "Dealers",
  news: "News and Activities",
  product: "Products",
  search: "Search results",
  supportAndDownload: "Support and Download",
  policy: "Policy"
}

export default title
