import home from "@/locales/th/home"
import title from "@/locales/th/title"
import footer from "@/locales/th/footer"
import aboutUs from "@/locales/th/aboutUs"
import cookiesPolicy from "@/locales/th/cookiesPolicy"
import contactUs from "@/locales/th/contactUs"
import news from "@/locales/th/news"
import support from "@/locales/th/support"
import download from "@/locales/th/download"
import career from "@/locales/th/career"
import dealer from "@/locales/th/dealer"
import global from "@/locales/th/global"
import product from "@/locales/th/product"
import calculate from "@/locales/th/calculate"
import search from "@/locales/th/search"
import menu from "@/locales/th/menu"
import policy from "@/locales/th/policy"

const th = {
  global,
  menu,
  home,
  title,
  footer,
  aboutUs,
  cookiesPolicy,
  contactUs,
  news,
  support,
  download,
  career,
  dealer,
  product,
  calculate,
  search,
  policy
}

export default th
