const aboutUs = {
  tabName: {
    companyProfile: "ข้อมูลบริษัท",
    vision: "วิสัยทัศน์ พันธกิจ และค่านิยมองค์กร",
    strategy: "กลยุทธ์การแข่งขัน",
    standard: "นโยบายคุณภาพ และมาตรฐาน",
    csr: "รายงานความรับผิดชอบต่อสังคม (CSR) สู่ความยั่งยืน"
  },
  companyProfile: {
    title: "ข้อมูลบริษัท",
    historyTitle: "ประวัติความเป็นมา",
    locationTitle: "สถานที่ตั้ง",
    companyName: "ชื่อบริษัท",
    companyShortName: "ชื่อย่อ",
    companyNumber: "เลขทะเบียนบริษัท",
    typeBusiness: "ประเภทธุรกิจ",
    registeredCapital: "ทุนจดทะเบียน",
    headquarters: "สำนักงานใหญ่",
    firstBranch: "สำนักงานสาขาที่ 1",
    secondBranch: "สำนักงานสาขาที่ 2",
    thirdBranch: "สำนักงานสาขาที่ 3",
    callCenter: "Call Center",
    website: "Website",
    email: "E-mai Address"
  },
  companyVision: {
    vision: "วิสัยทัศน์",
    mission: "พันธกิจ",
    values: "ค่านิยมองค์กร"
  },
  strategy: {
    title: "กลยุทธ์การแข่งขัน"
  },
  standard: {
    policy: "นโยบายคุณภาพ",
    standard: "มาตรฐานคุณภาพ",
    activity: "กิจกรรมระบบบริหารงานคุณภาพ และ กิจกรรมสนับสนุนเพื่อเพิ่มผลผลิต"
  },
  csr: {
    title: "รายงานความรับผิดชอบต่อสังคม (CSR) สู่ความยั่งยืน"
  }
}

export default aboutUs
