const contactUs = {
  success: "Message sent",
  contact: {
    title: "Contact Us",
    caption1: "Please filling in the blank to message us",
    caption2: "Please attach file if any",
    form: {
      name: "Name - Surname",
      tel: "Mobile Phone",
      email: "E-mail",
      subject: "Topic",
      detail: "Topic Detail",
      file: "Attach file",
      fileBtn: "Select file",
      remarks: "Only (doc, docx, xls, xlsx, ppt, pptx, jpg, jpeg, png, gif, bmp, pdf) " + "size less than 25 MB",
      required: "*required"
    }
  },
  branches: {
    title: "Branch",
    tel: "Tel.",
    fax: "Fax.",
    downloadMap: "Map Download"
  }
}

export default contactUs
