import { JsonProperty, Serializable } from "typescript-json-serializer"

@Serializable()
export default class DownloadFooterItem {
  @JsonProperty("download_header_id") id!: number

  @JsonProperty() title!: string

  @JsonProperty() type!: string

  @JsonProperty() filter!: number

  bold = false

  hover = false
}
