




import { Component, Vue } from "vue-property-decorator"
import TitleShape from "@/components/TitleShape.vue"

@Component({
  name: "NewsTitle",
  components: { TitleShape }
})
export default class NewsTitle extends Vue {}
