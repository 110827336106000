const home = {
  others: "Others",
  activities: "View all News & Activities",
  activity: "News & Activities",
  info: "DIAMOND News and Activities",
  announcement: "Announcement",
  video: "VDO Advertising",
  products: "View all products and services",
  product: "Products and Services",
  articles: "View all articles",
  article: "Blog",
  article_description: "This section is all about installations, House care and tips for your home"
}

export default home
