import "@fortawesome/fontawesome-free/css/all.css" // Ensure you are using css-loader
import Vue, { VNode } from "vue"
// // eslint-disable-next-line import/extensions,import/no-unresolved
import { DirectiveBinding } from "vue/types/options"
import { Swiper as SwiperClass, Lazy } from "swiper/js/swiper.esm"
import VueMeta from "vue-meta"
// @ts-ignore
import VueYoutube from "vue-youtube"
// @ts-ignore
import AOS from "aos"
import "aos/dist/aos.css"
import VueAwesomeSwiper from "vue-awesome-swiper"
import VueScrollTo from "vue-scrollto"

import App from "./App.vue"
import router from "./router"
import store from "./store"
import vuetify from "./plugins/vuetify"
import "@/index.css"
// import style (<= Swiper 5.x)
import "swiper/css/swiper.css"
import i18n from "./i18n"

SwiperClass.use([Lazy])

Vue.use(VueScrollTo)
Vue.use(VueYoutube)
Vue.use(VueAwesomeSwiper, {
  slidesPerView: 6,
  slidesPerGroup: 6,
  spaceBetween: 24
})
Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
})
Vue.config.productionTip = false

// eslint-disable-next-line @typescript-eslint/interface-name-prefix
interface IHTMLElementClicketyClick extends HTMLElement {
  clickOutsideEvent?(event: MouseEvent): void
}

Vue.directive("click-outside", {
  bind(el: IHTMLElementClicketyClick, binding: DirectiveBinding, vnode: VNode) {
    // stack-overflow: https://stackoverflow.com/a/42389266/13031497
    // eslint-disable-next-line no-param-reassign,func-names
    el.clickOutsideEvent = function(event: MouseEvent): void {
      if (
        !(el === event.target) ||
        // eslint-disable-next-line no-mixed-operators
        (event.target instanceof Node && el.contains(event.target))
      ) {
        // @ts-ignore
        vnode.context[binding.expression](event)
      }
    }
    // ugly, but fix the bug:
    // 2020/11/20 - Peterlits Zo
    // When I click the the ellipsis-h buttom, it do not change anything.
    // I find that itself call this function auto, so I add this after times.
    setTimeout(() => {
      // @ts-ignore
      document.body.addEventListener("click", el.clickOutsideEvent)
    }, 50)
  },
  unbind(el: HTMLElement) {
    // @ts-ignore
    document.body.removeEventListener("click", el.clickOutsideEvent)
  }
})
new Vue({
  created: () => {
    AOS.init({
      once: false,
      mirror: true
    })
  },
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App)
}).$mount("#app")
