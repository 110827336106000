import { JsonProperty, Serializable } from "typescript-json-serializer"

@Serializable()
export default class SupportFooterItem {
  @JsonProperty("support_header_id") id!: number

  @JsonProperty() type!: string

  @JsonProperty() title!: string

  @JsonProperty() filter!: number

  static = false

  bold = false

  hover = false
}
