import { JsonProperty, Serializable } from "typescript-json-serializer"

@Serializable()
export default class SubMenu {
  @JsonProperty("menu_sub_id") id!: number

  @JsonProperty("product_category_id") productCategoryId!: number

  @JsonProperty("product_group_id") productGroupId!: number

  @JsonProperty("title") label!: string
}
