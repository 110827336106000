import { JsonProperty, Serializable } from "typescript-json-serializer"

@Serializable()
export default class SEO {
  @JsonProperty() description!: string

  @JsonProperty() keywords!: string

  @JsonProperty("DC_title") dcTitle!: string
}
