const support = {
  title: "สื่อประชาสัมพันธ์",
  tabs: {
    faq: "FAQ",
    siteRef: "โครงการตัวอย่าง",
    blog: "บทความ"
  },
  blog: {
    yearPrefix: "บทความปี "
  }
}

export default support
