const title = {
  aboutUs: "เกี่ยวกับเรา",
  calculate: "คำนวณวัสดุ",
  career: "ร่วมงานกับเรา",
  contactUs: "ติดต่อเรา",
  cookiePolicy: "นโยบายคุ้มครองข้อมูลส่วนบุคคล",
  dealer: "ตัวแทนจำหน่าย",
  news: "ข่าวสารและกิจกรรม",
  product: "สินค้าและบริการ",
  search: "ผลการค้นหา",
  supportAndDownload: "ข้อมูลและดาวน์โหลด",
  policy: "นโยบายการยอมรับวัตถุประสงค์เพื่อการสมัครงาน"
}

export default title
