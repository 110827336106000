import { render, staticRenderFns } from "./PolicyTitle.vue?vue&type=template&id=0807f5ad&scoped=true&"
import script from "./PolicyTitle.vue?vue&type=script&lang=ts&"
export * from "./PolicyTitle.vue?vue&type=script&lang=ts&"
import style0 from "./PolicyTitle.vue?vue&type=style&index=0&id=0807f5ad&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0807f5ad",
  null
  
)

export default component.exports