








































































import { Component, PropSync, Watch, Vue } from "vue-property-decorator"
/** Components */
import ModalBase from "@/components/ModalBase.vue"
import { Cookies } from "@/utils/cookies"

@Component({
  name: "ModalSettingPolicy",
  components: { ModalBase }
})
export default class extends Vue {
  /** Variable */
  private isCookie = {
    analyticalCookies: false,
    fuctionalityCookies: false,
    advertisingCookies: false
  }

  @PropSync("value") isActive!: boolean

  @Watch("value", { immediate: true, deep: true })
  onUrlChange(val: boolean) {
    if (val) {
      const cookiesKey = Cookies.all.map((c) => c.key)

      this.isCookie = {
        analyticalCookies: !!Cookies.getCookieByKey(Cookies.acceptAnalytic),
        fuctionalityCookies: !!Cookies.getCookieByKey(Cookies.acceptFuctional),
        advertisingCookies: !!Cookies.getCookieByKey(Cookies.acceptAds)
      }
    }
    // Router from vue-router
    // this.fetchData()
  }

  private cookies = ["analyticalCookies", "fuctionalityCookies", "advertisingCookies"]

  get modalWidth() {
    const width = window.screen.width
    if (width > 1440) {
      return 1372
    } else if (width > 768) {
      return 848
    } else {
      return 311
    }
  }

  private submit() {
    if (this.isCookie.analyticalCookies) {
      Cookies.setAnalyticCookies()
    } else {
      Cookies.removeAnalyticCookies()
    }

    if (this.isCookie.fuctionalityCookies) {
      Cookies.setFuctionalCookies()
    } else {
      Cookies.removeFuctionalCookies()
    }

    if (this.isCookie.advertisingCookies) {
      Cookies.setAdsCookies()
    } else {
      Cookies.removeAdsCookies()
    }

    const [accKey] = Cookies.acceptCookies
    Cookies.setCookies({ key: accKey, value: "1" })

    this.$emit("close", true)
  }

  private async submitAll() {
    Cookies.setAnalyticCookies()
    Cookies.setFuctionalCookies()
    Cookies.setAdsCookies()

    const [accKey] = Cookies.acceptCookies
    Cookies.setCookies({ key: accKey, value: "1" })

    this.$emit("close", true)
  }
}
