import { JsonProperty, Serializable } from "typescript-json-serializer"
import DiamondFile from "@/types/DiamondFile"

@Serializable()
export default class Banner {
  @JsonProperty("banner_id") id!: number

  @JsonProperty() status!: string

  @JsonProperty({ type: DiamondFile }) file: DiamondFile = new DiamondFile()
}
