import home from "@/locales/en/home"
import title from "@/locales/en/title"
import footer from "@/locales/en/footer"
import aboutUs from "@/locales/en/aboutUs"
import cookiesPolicy from "@/locales/en/cookiesPolicy"
import contactUs from "@/locales/en/contactUs"
import news from "@/locales/en/news"
import support from "@/locales/en/support"
import download from "@/locales/en/download"
import career from "@/locales/en/career"
import dealer from "@/locales/en/dealer"
import global from "@/locales/en/global"
import product from "@/locales/en/product"
import calculate from "@/locales/en/calculate"
import search from "@/locales/en/search"
import menu from "@/locales/en/menu"
import policy from "@/locales/en/policy"

const en = {
  global,
  menu,
  home,
  title,
  footer,
  aboutUs,
  cookiesPolicy,
  contactUs,
  news,
  support,
  download,
  career,
  dealer,
  product,
  calculate,
  search,
  policy
}

export default en
