const calculate = {
  calculateBtn: "Calculate",
  result: "Calculated Result",
  fillTheFormRoof: "Please fill in the form",
  fillTheForm: "Please fill in the form",
  remarks1: "*Total price is according to Standard color and limited only for ",
  remarks2: "Bangkok metropolitan area. VAT and Transportation cost are not include.",
  totalPrice: "Total price",
  baht: "baht",
  hint: "*Please add more 1.5 double for Thai Classic and Elegant",
  note: "Notice",
  notice1: "Notice: This is a primary estimate price and material only.",
  notice2: "Please confirm price and plan with our staff before order."
}

export default calculate
