const product = {
  filter: {
    close: "ปิดฟิลเตอร์"
  },
  detail: {
    chosenColor: "สีที่เลือก",
    productColor: "สีสินค้า",
    hint: "สีของผลิตภัณฑ์อาจแตกต่างจากสีของผลิตภัณฑ์จริงเล็กน้อยควรพิจารณาสีสินค้าจริงที่ร้านค้าก่อนสั่งซื้อ",
    compare: "เปรียบเทียบ",
    compareProduct: "เปรียบเทียบสินค้า",
    clearCompare: "ล้างข้อมูล",
    detail: "รายละเอียด",
    delete: "ลบ",
    deleteAll: "ลบทั้งหมด",
    messageAlready: "คุณมีรายการสินค้านี้ ในรายการเปรียบเทียบแล้ว",
    messageAtlease: "เลือกสินค้าอย่างน้อย 1 ชิ้นเพื่อเปรียบเทียบ",
    messageNotMatchGroup: "เปรียบเทียบสินค้าได้เฉพาะภายใต้กลุ่มสินค้าเดียวกันเท่านั้น",
    messageMaxLength: "เลือกสินค้าเพื่อเปรียบเทียบได้มากที่สุด 3 ชิ้น",
    productName: "ชื่อสินค้า",
    usageImage: "ภาพการใช้งาน",
    colorRemarks:
      "สีของผลิตภัณฑ์อาจแตกต่างจากสีของผลิตภัณฑ์จริงเล็กน้อย\n" + "ควรพิจารณาสีสินค้าจริงที่ร้านค้าก่อนสั่งซื้อ",
    referenceTitle: "คุณสมบัติพิเศษ",
    download: "ดาวน์โหลด",
    seeVdo: "ดูวิดีโอ",
    buttons: {
      manual: "คู่มือการติดตั้ง",
      brochure: "โบรชัวร์",
      sampleProject: "โครงการตัวอย่าง",
      usage: "ภาพการใช้งาน",
      vdo: "วิดีโอ",
      docs: "เอกสารที่เกี่ยวข้อง"
    },
    accessories: "วัสดุและอุปกรณ์ประกอบ"
  }
}

export default product
