const career = {
  openPosition: "Available Positions",
  moreInfo: "For more information contact HR Department " + "Diamond Building Products Public Company Limited",
  workWithUs: "Work with us",
  detail: "Description",
  apply: "Apply",
  tel: "Tel : ",
  email: "Email : ",
  form: {
    title: "Apply for",
    name: "Name",
    tel: "Tel.",
    email: "E-mail",
    back: "Back",
    file: "Attach CV",
    fileBtn: "Select File",
    remarks: "Only (doc, docx, xls, xlsx, ppt, pptx, jpg, jpeg, png, gif, bmp, pdf) " + "size less than 25 MB",
    submitBtn: "Message us"
  },
  accept: "Accept"
}

export default career
