






































































































































// @ts-nocheck
/* eslint-disable global-require */
import { Component, Watch } from "vue-property-decorator"
import { mixins } from "vue-class-component"
import AppApi from "@/api/app/AppApi"
import Footer from "@/types/Footer/Footer"
import { deserialize } from "typescript-json-serializer"
import ProductFooterItem from "@/types/Footer/ProductFooterItem"
import SupportFooterItem from "@/types/Footer/SupportFooterItem"
import DownloadFooterItem from "@/types/Footer/DownloadFooterItem"
import { mapGetters } from "vuex"
import _, { constant } from "lodash"
import qs from "qs"
import BreakpointMixin from "../types/BreakpointMixin"

@Component({
  name: "FooterPage",
  computed: {
    ...mapGetters(["cookiesBar"]),
    queryLang() {
      return this.$route.query.lang || "th"
    }
  }
})
export default class FooterPage extends mixins(BreakpointMixin) {
  private baseFileUrl = process.env.VUE_APP_FILE_PATH

  private model = {
    IR: ""
  }

  private footer = new Footer()

  // eslint-disable-next-line class-methods-use-this
  private goToUrl(link: string) {
    window.open(link, "_blank")
  }

  @Watch("$route", {
    immediate: true,
    deep: true
  })
  onUrlChange() {
    // Router from vue-router
    this.fetchData()
  }

  @Watch("queryLang")
  onParamsChange() {
    // Router from vue-router

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth"
    })
    
    this.fetchData()
  }

  private fetchData() {
    AppApi.getIRLink().then(({ data: { link } }) => {
      this.model.IR = link
    })

    AppApi.getFooter().then(({ data }) => {
      this.footer = deserialize<Footer>(data, Footer)
      const tailSupport = this.footer.support
      let headSupport = [
        {
          title: "sampleProjects",
          id: -1,
          static: true
        },
        {
          title: "blog",
          id: 0,
          static: true
        },
        {
          title: "faq",
          id: -2,
          static: true
        }
      ] as SupportFooterItem[]

      if (this.$store.state.menus.blogs !== "ACTIVE") {
        headSupport = headSupport.filter((header) => header.title !== "blog")
      }

      this.footer.support = _.concat(headSupport, tailSupport)
    })

    AppApi.getSocialIcon().then(({ data }) => {
      this.social = data
    })
  }

  private mounted() {
    this.fetchData()
  }

  // @ts-ignore
  private viewRoute(item: { link: string; type: string; query: object }) {
    const query = item.query || {}

    if (item.type === "ROUTER" && this.$route.name !== item.link) {
      this.$router.push({
        name: item.link,
        query: {
          top: "true",
          lang: this.$i18n.locale,
          ...query
        }
      })
      // @ts-ignore
    }
    // else if (item.type === "MODEL") window.open(this.model[item.link], "_blank")
  }

  // eslint-disable-next-line class-methods-use-this
  private viewFooter(model: string, data: ProductFooterItem | SupportFooterItem | DownloadFooterItem) {
    const langQuery = `lang=${localStorage.getItem("lang") || "th"}`
    if (model === "product") {
      // eslint-disable-next-line @typescript-eslint/camelcase
      localStorage.setItem("productFilter", qs.stringify({ product_group: data.id }))
      window.location.href = `/products/group/${data.id}?${langQuery}`
    } else {
      window.location.href = `/support-and-download?view=${model}&id=${data.id}&${langQuery}`
    }
  }

  private social = [
    {
      alt: "facebook",
      icon: require("@/assets/footer/facebook.png"),
      hoverIcon: require("@/assets/footer/facebook-hover.svg"),
      link: "https://www.facebook.com/DIAMONDBrandOfficial/",
      hover: false
    },
    {
      alt: "line",
      icon: require("@/assets/footer/line.svg"),
      hoverIcon: require("@/assets/footer/line-hover.svg"),
      link: "https://lin.ee/adPQbmM",
      hover: false
    },
    {
      alt: "twitter",
      icon: require("@/assets/footer/twitter.png"),
      hoverIcon: require("@/assets/footer/twitter-hover.svg"),
      link: "https://twitter.com/DiamondBrandTH",
      hover: false
    },
    {
      alt: "instagram",
      icon: require("@/assets/footer/instagram.png"),
      hoverIcon: require("@/assets/footer/instagram-hover.svg"),
      link: "https://www.instagram.com/diamondbrandofficial/",
      hover: false
    },
    {
      alt: "youtube",
      icon: require("@/assets/footer/youtube.png"),
      hoverIcon: require("@/assets/footer/youtube-hover.svg"),
      link: "https://www.youtube.com/channel/UCJHJxVKPq20IuEQA8-rVUug",
      hover: false
    },
    {
      alt: "email",
      icon: require("@/assets/footer/email.svg"),
      hoverIcon: require("@/assets/footer/email-hover.svg"),
      link: "https://mail.dbp.co.th/owa/",
      hover: false
    }
  ]

  private templates = [
    {
      title: "aboutUs",
      items: [
        {
          label: "aboutUs",
          bold: true,
          hover: false,
          link: "AboutUs",
          type: "ROUTER"
        },
        {
          label: "ir",
          bold: true,
          hover: false,
          link: "IR",
          type: "MODEL"
        },
        // {
        //   label: 'productWarranty',
        //   bold: true,
        //   hover: false,
        //   type: 'ROUTER',
        //   link: 'SupportAndDownload',
        //   query: {
        //     view: 'support',
        //     id: 1,
        //   },
        // },
        // {
        //   label: 'cookiesPolicy',
        //   bold: true,
        //   hover: false,
        //   link: 'CookiesPolicy',
        //   type: 'ROUTER',
        // },
        {
          label: "newsAndActivities",
          bold: true,
          hover: false,
          link: "NewsListPage",
          type: "ROUTER"
        },
        // {
        //   label: 'dealers',
        //   bold: true,
        //   hover: false,
        //   link: 'Dealers',
        //   type: 'ROUTER',
        // },
        {
          label: "workWithUs",
          bold: true,
          hover: false,
          link: "CareerMainPage",
          type: "ROUTER"
        },
        {
          label: "contactUs",
          bold: true,
          hover: false,
          link: "ContactUs",
          type: "ROUTER"
        },
        {
          label: "cookiesPolicy",
          bold: true,
          hover: false,
          link: "CookiesPolicy",
          type: "ROUTER"
        }
      ]
    },
    {
      title: "products",
      link: "ProductGroupList",
      model: "product"
    },
    {
      title: "download",
      model: "download"
    },
    {
      title: "support",
      model: "support"
    }
  ]

  private get template() {
    return this.templates.map((template) => {
      if (template.items) {
        template.items = template.items.filter((item) => {
          if (this.$store.state.menus.activities === "ACTIVE") {
            return item
          } else {
            return item.label !== "newsAndActivities"
          }
        })
        return template
      } else {
        return template
      }
    })
  }

  private storageLang() {
    return localStorage.getItem("lang") || "th"
  }

  private generateRoute(item: { link: string; type: string; query: object }) {
    const query = this.$route.query || {}

    if (item.type === "ROUTER" && this.$route.name !== item.link) {
      return {
        name: item.link,
        query: {
          top: "true",
          lang: this.$i18n.locale
        }
      }
    } else if (item.type === "MODEL") {
      return { href: `${this.model[item.link]}`, attr: "_self" }
    } else {
      return { href: "#", attr: "_self" }
    }
  }

  private generateRouteFooter(model: string, data: ProductFooterItem | SupportFooterItem | DownloadFooterItem) {
    const langQuery = `lang=${localStorage.getItem("lang") || "th"}`
    if (model === "product") {
      if(data.title === 'คำนวณวัสดุ' || data.title === 'CALCULATION' || data.id === 23 || data.id === 24){
        return `/calculate/?top=true&${langQuery}`
      }
      // eslint-disable-next-line @typescript-eslint/camelcase
      localStorage.setItem("productFilter", qs.stringify({ product_group: data.id }))
      return `/products/group/${data.id}?${langQuery}&top=true`
    } else {
      return `/support-and-download?view=${model}&id=${data.id}&${langQuery}&top=true`
    }
  }
}
