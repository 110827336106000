import store from "@/store/index"
import axios, { AxiosInstance } from "axios"

const baseURL = process.env.VUE_APP_API_PATH

function createInstance(): AxiosInstance {
  const instance = axios.create({
    baseURL
    // params: {
    //   lang: store.getters.language, // TODO get this from global store
    // },
  })
  instance.interceptors.request.use((config) => {
    // eslint-disable-next-line no-param-reassign
    config.params = {
      ...config.params,
      lang: store.getters.language
    }
    return config
  })
  return instance
}

export default class DiamondBaseAPI {
  static api = createInstance()
}
