import { JsonProperty, Serializable } from "typescript-json-serializer"

@Serializable()
export default class ProductFooterItem {
  @JsonProperty("product_group_id") id!: number

  @JsonProperty() title!: string

  bold = false

  hover = false
}
