import DiamondBaseAPI from "@/api/Api"
import ResponseBase from "@/types/ResponseBase"
import Logo from "@/types/App/Logo"
import IRLink from "@/types/App/IRLink"
import Footer from "@/types/Footer/Footer"
import SocialMediaIcon from "@/types/Footer/SocialMediaIcon"
import Banner from "@/types/App/Banner"
import SEO from "@/types/App/SEO"

export default class AppApi extends DiamondBaseAPI {
  static getLogo(): Promise<ResponseBase<Logo>> {
    return this.api.get("logo").then(({ data }) => data)
  }

  static getIRLink(): Promise<ResponseBase<IRLink>> {
    return this.api.get("ir").then(({ data }) => data)
  }

  static getFooter(): Promise<ResponseBase<Footer>> {
    return this.api.get("footer").then(({ data }) => data)
  }

  static getSocialIcon(key = "ACTIVE"): Promise<ResponseBase<SocialMediaIcon>> {
    const params = { status: key }
    return this.api.get("social-menus", { params }).then(({ data }) => data)
  }

  static getBanner(key: string): Promise<ResponseBase<Banner>> {
    const params = { page: key }
    return this.api.get("banner", { params }).then(({ data }) => data)
  }

  static getSEO(): Promise<ResponseBase<SEO>> {
    return this.api.get("seo").then(({ data }) => data)
  }
}
