import Vue from "vue"
import Vuex from "vuex"
import createPersistedState from "vuex-persistedstate"

Vue.use(Vuex)

interface productForCompare {
  id: number
  title: string
  filePath: string
  productCategoryId: number
  productCategoryName: number
  productGroupId: number
}

export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    cookieBar: true,
    snackBar: false,
    msgSnack: "",
    language: "th",
    menus: {
      blogs: "ACTIVE",
      activities: "ACTIVE"
    },
    compareBar: false,
    dimensions: [] as Array<productForCompare>,
    groupId: null as any,
    isProcess: false
  },
  mutations: {
    setCookiesBar(state, payload: boolean) {
      state.cookieBar = payload
    },
    setLanguage(state, payload: string) {
      state.language = payload
    },
    setMenus(state, payload: { blogs: ""; activities: "" }) {
      state.menus.blogs = payload.blogs
      state.menus.activities = payload.activities
    },
    setCompare(state, payload: productForCompare) {
      const dimensions: string = localStorage.getItem("dimensions") || ""
      const _dimensions = state.dimensions.length ? state.dimensions : dimensions ? JSON.parse(dimensions) : []

      localStorage.removeItem("groupId")
      localStorage.removeItem("dimensions")
      localStorage.setItem("groupId", `${payload.productGroupId}`)
      localStorage.setItem("dimensions", JSON.stringify([..._dimensions, { ...payload }]))
      state.groupId = payload.productGroupId
      state.dimensions = [..._dimensions, { ...payload }]
    },
    clearCompare(state) {
      state.groupId = null
      state.dimensions = []
      localStorage.removeItem("groupId")
      localStorage.removeItem("dimensions")
    },
    setDimension(state, payload: productForCompare[]) {
      localStorage.removeItem("dimensions")
      localStorage.setItem("dimensions", JSON.stringify([...payload]))
      state.dimensions = payload
    },
    toggleSnack(state, payload: boolean) {
      if (!payload) {
        state.msgSnack = ""
      }

      state.snackBar = payload
    },
    setMsgSnack(state, payload: string) {
      state.msgSnack = payload
    },
    setIsProcess(state, payload) {
      state.isProcess = payload
    }
  },
  actions: {
    closeCookiesBar({ commit }) {
      commit("setCookiesBar", false)
    },
    setEnglishLanguage({ commit }) {
      localStorage.setItem("lang", "en")
      commit("setLanguage", "en")
    },
    setThaiLanguage({ commit }) {
      localStorage.setItem("lang", "th")
      commit("setLanguage", "th")
    },
    setLanguage({ commit }, lang: string) {
      localStorage.setItem("lang", lang)
      commit("setLanguage", "th")
    },

    setCompareItems({ commit }, payload: productForCompare) {
      commit("setCompare", {
        id: payload.id,
        title: payload.title,
        filePath: payload.filePath,
        productCategoryId: payload.productCategoryId,
        productCategoryName: payload.productCategoryName,
        productGroupId: payload.productGroupId
      })
    }
  },
  getters: {
    cookiesBar: (state) => state.cookieBar,
    language: (state) => state.language
  },
  modules: {}
})
