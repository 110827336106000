



















































import { Component, Prop, Vue } from "vue-property-decorator"
import DiamondFile from "@/types/DiamondFile"
import SubMenu from "@/types/Menu/SubMenu"
import { mapGetters } from "vuex"
import qs from "qs"

@Component({
  name: "SubMenuBody",
  components: {},
  computed: { ...mapGetters(["cookiesBar"]) }
})
export default class SubMenuBody extends Vue {
  @Prop({ type: Object, required: true })
  data!: { data: SubMenu[]; image: DiamondFile }

  @Prop({ type: Boolean, default: false })
  right!: boolean

  private cookiesBar!: boolean

  // eslint-disable-next-line class-methods-use-this
  private visitMenu(groupId: number, categoryId: number) {
    localStorage.setItem(
      "productFilter",
      qs.stringify({
        // eslint-disable-next-line @typescript-eslint/camelcase
        product_group: groupId
      })
    )
    const langQuery = `?lang=${localStorage.getItem("lang") || "th"}`
    window.location.href = `/products/group/${groupId}/detail/${categoryId}${langQuery}`
  }

  private animationTime: { [key: number]: number } = {}

  get items() {
    if (this.data.data) {
      const lst: SubMenu[] = this.data.data
      // eslint-disable-next-line no-plusplus
      // for (let i = 0; i < 16; i++) {
      //   lst.push({
      //     id: i,
      //     productCategoryId: 6,
      //     productGroupId: 4,
      //     label: 'อิฐมวลเบาไดมอนด์บล็อก',
      //   });
      // }
      const ret = []
      let col = []
      let sec = 0.25
      // eslint-disable-next-line no-empty,no-plusplus
      for (let i = 1; i <= lst.length; i++) {
        const elt = lst[i - 1]
        col.push(elt)
        this.animationTime[elt.id] = sec
        if (i % 8 === 0 || i === lst.length) {
          sec += 0.25
          ret.push(col)
          col = []
        }
      }

      // let sec = 0.25;
      // if (!ret[0]) return [];
      // // eslint-disable-next-line no-plusplus
      // for (let j = 0; j < ret[0].length; j++) {
      //   // eslint-disable-next-line no-plusplus,no-empty
      //   for (let colNo = 0; colNo < ret.length; colNo++) {
      //     const elt = ret[colNo][j];
      //     if (elt) {
      //       this.animationTime[elt.id] = sec;
      //       // sec += 1;
      //     } else {
      //       break;
      //     }
      //   }
      // }

      return ret
    }
    return []
  }

  get image() {
    return this.data.image ? this.data.image.fileUrl : ""
  }

  private storageLang() {
    return localStorage.getItem("lang") || "th"
  }
  selected = null
}
