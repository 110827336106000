






















import { Component, PropSync, Prop } from "vue-property-decorator"
import { mixins } from "vue-class-component"
import BreakpointMixin from "@/types/BreakpointMixin"

@Component({
  name: "SearchMenu",
  components: {}
})
export default class SearchMenu extends mixins(BreakpointMixin) {
  @PropSync("search") searchData!: string
  @Prop({ default: false }) isMobile!: boolean

  private submit(): void {
    if (this.searchData) {
      this.$emit("close-search")
      this.$router.push({ name: "Search", query: { q: this.searchData } })
    }
  }
}
