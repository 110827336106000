import DiamondBaseAPI from "@/api/Api"
import Branch from "@/types/ContactUs/Branch"
import ResponseBase from "@/types/ResponseBase"
import ContactUs from "@/types/ContactUs/ContactUs"
import SubMenuBanner from "@/types/ContactUs/SubMenuBanner"
import _ from "lodash"
import { serialize } from "typescript-json-serializer"

export default class ContactUsApi extends DiamondBaseAPI {
  static getSubMenuBanner(): Promise<any> {
    return this.api.get("contact-us").then(({ data }) => {
      return data.data[0]
    })
  }

  static listBranches(): Promise<ResponseBase<Branch[]>> {
    return this.api.get("branch").then(({ data }) => data)
  }

  static submitForm(contact: ContactUs): Promise<void> {
    const body = serialize(contact, false)
    const form = new FormData()
    _.forEach(body, (value, key) => {
      form.append(key, value)
    })
    return this.api
      .post("contact", form, {
        headers: { "Content-Type": "multipart/form-data" }
      })
      .then(({ data }) => data)
  }
}
