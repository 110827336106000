const dealer = {
  prefix: "ตัวแทนจำหน่าย",
  modernTrade: "โมเดิร์นเทรด",
  leadTrade: "ชั้นนำ",
  nextBtn: "ถัดไป",
  prevBtn: "ก่อนหน้า",
  map: "แผนที่",
  sortBy: "เรียงตาม",
  alphabet: "ตัวอักษร ก-ฮ",
  table: {
    shopName: "ชื่อร้าน",
    tel: "โทรศัพท์",
    address: "ที่อยู่"
  }
}

export default dealer
