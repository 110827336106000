






















import { Component, Prop, PropSync, Vue } from "vue-property-decorator"
// import loader, { apiKey } from "@/plugins/googleMaps"
import loader from "@/plugins/googleMaps"

@Component({
  name: "MapDialog"
})
export default class MapDialog extends Vue {
  @PropSync("model") mapDialog!: boolean

  @Prop() content!: string

  private isLoaded = false

  // private get template() {
  //   return `<iframe
  //       width="100%"
  //       height="500px"
  //       style="border:0"
  //       loading="lazy"
  //       allowfullscreen
  //       src="https://www.google.com/maps/embed/v1/place?key=${apiKey}&q=place_id:${this.placeId}&language="${this.$i18n.locale}"/>`;
  // }

  private mounted() {
    loader.load().then(() => {
      this.isLoaded = true
    })
  }

  // @Watch('mapDialog')
  // async onMapOpen() {
  //   if (!this.mapDialog) return;
  // const position = {
  //   lat: this.lat,
  //   lng: this.lng,
  // };
  // eslint-disable-next-line @typescript-eslint/no-var-requires,global-require
  // const markerIconUrl = require('@/assets/icons/LocationPoint.png');
  // const markerIcon: Icon = {
  //   url: markerIconUrl,
  //   // eslint-disable-next-line no-undef
  //   scaledSize: new google.maps.Size(90, 144),
  // };
  // if (!this.isLoaded) await loader.load();
  // setTimeout(() => {
  // // eslint-disable-next-line no-undef
  // const geocoder = new google.maps.Geocoder();
  // geocoder.geocode({ location: position }, (results, status) => {
  //   if (status === 'OK') {
  //     this.placeId = results[0].place_id;
  //     console.log('place!', this.placeId);
  //   }
  // });
  // eslint-disable-next-line no-undef,@typescript-eslint/no-non-null-assertion
  //   const map = new google.maps.Map(document.getElementById('mapDialog')!, {
  //     center: position,
  //     zoom: 15,
  //   });
  //   // eslint-disable-next-line no-undef,@typescript-eslint/no-unused-vars
  //   const marker = new google.maps.Marker({
  //     icon: markerIcon,
  //     position,
  //     map,
  //   });
  // }, 100);
  // }
}
