const aboutUs = {
  tabName: {
    companyProfile: "Company Profile",
    vision: "Vision, Mission statement and Corporate Values",
    strategy: "Competing Strategies",
    standard: "Policy for Quality and Quality Standards",
    csr: "Corporate social responsibility (CSR) and sustainability report"
  },
  companyProfile: {
    title: "Company Profile",
    historyTitle: "History",
    locationTitle: "Address",
    companyName: "Company name",
    companyShortName: "Abbreviated Name",
    companyNumber: "Registration No",
    typeBusiness: "Business Type",
    registeredCapital: "Registered Capital",
    headquarters: "Head Office",
    firstBranch: "Branch office 1",
    secondBranch: "Branch office 2",
    thirdBranch: "Branch office 3",
    callCenter: "Call Center",
    website: "Website",
    email: "E-mai Address"
  },
  companyVision: {
    vision: "Vision",
    mission: "Mission",
    values: "Corporate Values"
  },
  strategy: {
    title: "Competing Strategies"
  },
  standard: {
    policy: "Policy for Quality",
    standard: "Quality Standards",
    activity: "Activities for quality job management system," + " and supporting activities to boost productivity"
  },
  csr: {
    title: "Corporate social responsibility (CSR) and sustainability report"
  }
}

export default aboutUs
