const footer = {
  aboutUs: "About us",
  ir: "Investor Relation",
  productWarranty: "Conditional Product",
  legalAndPolicy: "Legal and Policy",
  cookiesPolicy: "Cookie Policy",
  newsAndActivities: "News & Activities",
  dealers: "Diamond Dealers",
  workWithUs: "Work with us",
  contactUs: "Contact Us",
  products: "Products",
  download: "Download",
  support: "Support",
  faq: "FAQ",
  sampleProjects: "Sample Projects",
  blog: "Blog"
}

export default footer
